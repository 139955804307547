import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useTitle } from '@vueuse/core';

import {
  getToken,
  getTokenKey,
  setToken,
  setUserCache,
  setDisplay,
  getSubToken,
  setSubAlias,
  resetCertificationView,
  setVersionName,
  getHelperStatus,
  getHelperData,
  getHelperUrl,
  setFuncRole,
  removeToken,
  setOriginMenuStatus,
  getOriginMenuStatus,
  getMenuVisibleRouterKey,
  getExamineStatus,
  getExamine,
  getCertificationView
} from '@/plugins/cookie';
import http from '@/api/user';
import api from '@/api/home';

import store from '../store/index';
import { mediaMenuHelp } from './role';
import { liveMenu } from './liveMenu';
import { homeMenu } from './homeMenu';
import { gatewayMenu } from './gatewayMenu';
/**
 * @descriptionmeta 包含相关关键字作用：
 *  父组件
 * @title - 标题
 * @icon - 菜单图标
 * @menuType - 菜单分类 首页菜单与进入直播间后的菜单区分
 * @userMenu {boolean} 是否在右上角菜单显示
 *
 * 子组件
 * @routeParent - 子选项的父级菜单标识
 * @hidden {boolean} 是否在左侧菜单显示
 * @customMode {boolean} 自定义mode层
 * @applink {boolean} 是否是站内路由
 * @role {boolean} 是否有权限
 * @only {boolean} 只能在对应role显示
 * @helperKey {string} 关键字对应助手接口返回关键字
 */

//基础路由
const is_mobile = navigator.userAgent.match(
  // eslint-disable-next-line no-irregular-whitespace
  /(phone|pod|iPhone|iPod|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|Symbian|Windows Phone)/i
);
const baseRoutes: Array<RouteRecordRaw> = [
  {
    path: '/:studioId/sub_login',
    name: 'subLogin',
    component: () => import('../views/subLogin.vue'),
    meta: {
      title: '登录',
      icon: '',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: is_mobile ? () => import('../views/Logins.vue') : () => import('../views/Login.vue'),
    meta: {
      title: '登录',
      icon: '',
    },
    beforeEnter: () => {
      resetCertificationView();
    },
  },
  {
    path: '/loginAssistant',
    name: 'HelperLogin',
    component: () => import('../views/Login/HelperLogin.vue'),
    meta: {
      title: '直播助手登录',
      icon: '',
    },
    beforeEnter: () => {
      resetCertificationView();
    },
  },
  {
    path: '/loginExamine',
    name: 'ExamineLogin',
    component: () => import('../views/Login/ExamineLogin.vue'),
    meta: {
      title: '第三方审核登录',
      icon: '',
    },
    beforeEnter: () => {
      resetCertificationView();
    },
  },
  //验证登录
  {
    path: '/verification',
    name: 'Verification',
    component: () => import('@/views/Verification.vue'),
    meta: {
      title: '实名认证',
      icon: '',
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue'),
    meta: {
      title: '404',
      icon: '',
    },
  },
  // {
  //   path: '/',
  //   name: 'Layout',
  //   component: () => import('../views/Home.vue'),
  //   meta: {
  //     title: '首页',
  //     icon: 'DesktopOutlined',
  //   },
  //   redirect: '/home',
  //   children: [
  //     {
  //       path: '/home',
  //       name: 'Home',
  //       component: () => import('../views/home/HomeInfo.vue'),
  //       meta: {
  //         title: '',
  //         icon: '',
  //         menuType: 'home',
  //       },
  //     },
  //     // ...homeMenu,
  //     // ...liveMenu,
  //     // ...gatewayMenu,
  //   ],
  // },
  {
    path: '/register',
    name: 'SignUp',
    component: !is_mobile ? () => import('../views/SignUp.vue') : () => import('../views/MobileSignup.vue'),
    meta: {
      title: '注册',
      icon: '',
    },
  },
  {
    path: '/lostpass',
    name: 'StartUp',
    component: is_mobile ? () => import('../views/MobileStartUp.vue') : () => import('../views/StartUp_res.vue'),
    meta: {
      title: '忘记密码',
      icon: '',
    },
  },
  {
    path: '/homeMask',
    name: 'HomeMask',
    component: () => import('../views/HomeMask.vue'),
    meta: {
      title: '首页',
      icon: '',
    },
  },
  // {
  //   path: '/:catchAll(.*)',
  //   redirect: '/404',
  // },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory('/'),
  routes: [...baseRoutes],
});

/**
 * 路由型权限
 * @description key对应路由名
 */
const routerRoleData = {
  UserList: 1265,
  Overview: 736, // 资源概览
  ResourcePurchase: 774, // 资源购买
  Wallet:1276, // 资源配置
  WalletRecharge: 624, // 账户充值
  WalletVersion: 777, // 版本购买
  baseBill: 369, // 账单统计
  SubManagement: 464, // 临时账号
  Subordinate: 857, // 子账号
  customServe: 783, // 定制服务
  LiveSub: 133, // 临时账号列表
  AiSubtitles: 985, // 多语实时字幕
  Sensitive: 1001, //敏感词权限
  LikeSetting: 991, //点赞权限
  AntiRecordingScreen: 1005, // 水印设置
  Population: 1092, //全局设置
  ChapterPlayback: 1094, //章节回放
  LiveData: 1136, //数据统计
  Application: 1148, //应用中心
  CardBag: 749, //卡包
  RobotReview: 576, //机器人评论
  RedEnvelopeRain:603, // 红包雨
  competitive_red_envelope:653, // 竟达红包
  RedPack:1274, // 控台红包
  PasswordRedEnvelope:1271, // 口令红包
  QuotaRedEnvelope:1272, //定额红包
  MatchLuckyRedEnvelope:1273, //拼手气红包
  SignUpToWatch:1011, // 报名观看
  AccountState:1277, // 强制关注公众号
  MessageCenter:577,// 消息通知,
  OfficialAccount:444, // 公众号设置
};

// 按钮型权限
const btnRoleData = {
  ninetyPromotion: 772, // 99元开播
  internationalCheckBox: 775, // 注册观看-国际短信
  lowLatency: 825, // 直播控台-低延迟开关
  oldVersion: 834, // header导航-切换旧版
  Certification: 863, //实名认证
  StandardLive: 845, // 标准版权限
  VerticalLive: 1020, //竖屏直播
  offerSide: 1114, // 首页侧边栏
  videoDoting: 1094, // 视频打点
  videoJianji: 285, // 视频剪辑/合并
  showCutVersion: 1132, //数据统计是否显示切换版本按钮
  CloudGuide: 1137, //云导播
  CloudStriping: 1141, // 云拆条
  SignWall: 1202, // 签到上墙
  FiveLuckyGame: 1193, // 集五福游戏
  virtuallyLive: 1177, // 虚拟直播
  panoramaLive: 1243, // VR直播
  videoLegibility: 1244, //视频清晰度
  interactiveLianmai: 1249, // 互动连麦权限
  ListLive:1278, //列表直播
  WebLiveStreaming:1275,// 网页直播，
  InteractiveLiveStreaming:571, // 互动直播
};

// 菜单型权限
const menuRoleData = {
  Site: 905,
  Gateway: 905,
};

/**
 * 权限请求对象
 * @desc 属性对应路由的 name 属性
 */
const roleRequertData = {
  ...routerRoleData,
  ...btnRoleData,
  ...menuRoleData,
};

// const addMenuItem = (data: RouteRecordRaw): void => {
//   if (!data.children) {
//     if (!data?.meta?.routeParent || data?.meta?.routeParent === data?.name) {
//       router.addRoute('Layout', data);
//     } else {
//       router.addRoute(data?.meta?.routeParent as string, data);
//     }
//     return;
//   }
//   data.children.forEach((e) => {
//     addMenuItem(e);
//   });
// };

let roleStatus = false;
export const formatRoutes = async (isLiveUser?: number): Promise<void> => {
  try {
    isLiveUser = isLiveUser ?? getHelperStatus(); // 是否是直播助手用户
    const isExamine = getExamineStatus(); //是否是第三方审核登录
    const Examine = getExamine();
    if (router.hasRoute('Layout')) router.removeRoute('Layout');
    const helperUrl = getHelperUrl();
    let routerArr: RouteRecordRaw[] = [];
    let redirectName = '/home';
    if (!isLiveUser) {
      routerArr = liveMenu.concat(homeMenu);
      if (isExamine) {
        redirectName = `/live_set/${Examine.studioId}/signup_watch`;
      }
    } else {
      redirectName = `/console/${helperUrl?.targetStudioId}`;
      routerArr = liveMenu.concat(mediaMenuHelp); // 添加直播缓存路由进直播助手路由表
      // routerArr = liveMenu;
    }

    let res = {};
    if (!isExamine) {
      const resData = await api.getFuncPermission({
        params: {
          item_ids: roleRequertData,
        },
      });

      res = resData.data.data;
      setFuncRole(res); // 临时保存权限与store共享
      if (res['Gateway']) {
        routerArr = routerArr.concat(gatewayMenu);
      }
      if (!res['UserList']) {
        const index = routerArr.findIndex((item) => item.path === '/user/list')
        if (index >= 0) {
          routerArr.splice(index, 1)
        }
      } 
      // if(!res['Wallet']){
      //   const index = routerArr.findIndex((item) => item.path === '/wallet')
      //   if (index >= 0) {
      //     routerArr.splice(index, 1)
      //   }
      // }
    }

    //  添加基础入口
    router.addRoute({
      path: '/',
      name: 'Layout',
      component: () => import('@/views/Home.vue'),
      meta: {
        title: '首页',
        icon: 'DesktopOutlined',
      },
      redirect: redirectName,
      children: [...routerArr],
    });

    const allRoleData = { ...routerRoleData, ...menuRoleData };
    Object.keys(allRoleData).forEach((e) => {
      if (!router.hasRoute(e) || res[e]) return;
      router.removeRoute(e);
    });
  } catch (err: any) {
    if (err?.data?.code === 100) {
      removeToken();
      router.removeRoute('Layout');
      router.replace('Login');
    }
  }
};

let originMenuVisible = getOriginMenuStatus(); // 控制页面显示菜单的状态;
const beforeLoginWhiteList = ['Login', 'StartUp', 'SignUp', 'subLogin', 'HelperLogin', 'ExamineLogin']; // 未登录的路由白名单
const baseWhiteList = ['Login', 'StartUp', 'SignUp']; // 登录后的路由白名单
const subWhiteList = ['Login', 'StartUp', 'SignUp', 'subLogin']; // 登录后的子账号路由白名单
const helperWhiteList = ['Login', 'StartUp', 'SignUp', 'subLogin', 'HelperLogin']; // 登录后的直播助手账号路由白名单
router.beforeEach(async (to, from, next) => {
  const userStatus = getToken();
  const subUserStatus = getSubToken();
  const userTypeStatus = getHelperStatus();
  const UserLoginStates = !userTypeStatus && userStatus;
  const HelperLoginStates = userTypeStatus && userStatus;
  const helpToList = ['404', 'Console'];

  // 存在lighostoken自动登录
  if (to.fullPath.includes(`?${getTokenKey()}`) || to.fullPath.includes(`&${getTokenKey()}`)) {
    if (getToken() !== to.query.lighostoken) {
      removeToken();
      setToken(to.query.lighostoken as string);
      try {
        const res: any = await http.getMemberInfo();
        const resData = res.data.data;
        resData['token'] = to.query.lighostoken;
        setUserCache(resData);
        store.commit('user/loginSaveUserInfo', resData);
        if (to.query.alias) {
          localStorage.setItem('alias', to.query.alias as string);
        }
        window.location.search = '';
      } catch (err: any) {
        console.error(err.data.error);
      }
    } else {
      window.location.search = '';
    }
  }
  // 菜单隐藏拦截
  if (originMenuVisible !== '1') {
    if (to.query[getMenuVisibleRouterKey()] === '1') {
      originMenuVisible = '1';
      setOriginMenuStatus(originMenuVisible);
    }
  }

  // 登录前拦截
  if ( !userStatus && !subUserStatus && !beforeLoginWhiteList.includes(to.name as string)) {
    const subAlias = localStorage.getItem('subState');
    console.log(subAlias,22);
    if(subAlias){
      console.log(11);
      router.push(`/${subAlias}/sub_login`);
      localStorage.removeItem('subState');
    }else{
      console.log(22);
      next({name:'Login'})
    }
    return;
  }
  // 动态路由生成
  if (!roleStatus) {
    roleStatus = true; // 避免登录进入后重复创建路由
    if (userStatus || subUserStatus) {
      await formatRoutes(userTypeStatus);
      router.addRoute({
        path: '/:catchAll(.*)',
        redirect: '/404',
      });
      next({ ...to, replace: true });
      return;
    }
  }

  // 已登录的过滤
  if (
    (UserLoginStates && baseWhiteList.includes(to.name as string)) ||
    (subUserStatus && subWhiteList.includes(to.name as string)) ||
    (HelperLoginStates && helperWhiteList.includes(to.name as string))
  ) {
    next('/');
    return;
  }
  // 初始化三级列表页路径
  if (to.path.includes(':')) {
    next(to.path.split(':')[0]);
    return;
  }
  if (HelperLoginStates && getHelperData()[to.meta?.helperKey as any] !== 1 && !helpToList.includes(to.name as string)) {
    next('/404');
    return;
  }
  next();
});

const title = useTitle();
const BaseTitle = '首页';

router.afterEach((to) => {
  // 手机登录页不现实logo 字段
  if (to.fullPath.includes('?') && to.fullPath.includes('display')) {
    setDisplay(to.query.display);
  }
  // 子账号截取字段
  if (to.fullPath.includes('?') && to.fullPath.includes('sub_alias')) {
    setSubAlias(to.query.sub_alias);
  }
  // 版本购买跳转旧
  if (to.fullPath.includes('?') && to.fullPath.includes('version_name')) {
    setVersionName(to.query.version_name);
  }
  title.value = (to?.meta?.title as string) ? (to?.meta?.title as string) : BaseTitle; // 设置title
});

export default router;
